import { defineCustomElements } from '@uefadigital/panenka/dist/loader';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

defineCustomElements();

const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// disable service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
}
