import { PkSpinner } from '@uefadigital/panenka-react';
import { lazy, Suspense } from 'react';
import { createHashRouter, Navigate } from 'react-router-dom';

const About = lazy(() => import('Pages/About/About'));
const ArticleView = lazy(() => import('Pages/ArticleView/ArticleView'));
const FootballNearYou = lazy(() => import('Pages/FootballNearYou/FootballNearYou'));
const HashtagView = lazy(() => import('Pages/HashtagView/HashtagView'));
const NotFound = lazy(() => import('Pages/NotFound/NotFound'));
const Partners = lazy(() => import('Pages/Partners/Partners'));
const Supporters = lazy(() => import('Pages/Supporters/Supporters'));
const Trending = lazy(() => import('Pages/Trending/Trending'));
const OffMute = lazy(() => import('Pages/OffMute/OffMute'));
const Main = lazy(() => import('Pages/Main/Main'));

// TODO: Temporary solution to show the off mute page in PRE environment.
// This should be removed once the off mute page is ready for production.
const allowedEnvironments = ['int', 'pre'];
const currentEnv = import.meta.env.VITE_APP_ENVIRONMENT;
//

export const PublicRoutes = {
  trending: '/trending',
  footballNearYou: '/football-near-you',
  supporters: '/supporters',
  partners: '/partners',
  about: '/about',
  articleView: '/article-view',
  hashtagView: '/hashtag-view',
  ...(allowedEnvironments.includes(currentEnv) && {
    offMute: '/off-mute',
    offMuteChoice: '/choice',
    offMuteConsent: '/consent',
  }),
} as const;

export const router = createHashRouter(
  [
    {
      path: '/',
      element: (
        <Suspense fallback={<PkSpinner />}>
          <Main />
        </Suspense>
      ),
      errorElement: (
        <Suspense fallback={<PkSpinner />}>
          <NotFound />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={PublicRoutes.trending} replace />,
        },
        {
          path: PublicRoutes.trending,
          element: <Trending />,
        },
        {
          path: PublicRoutes.about,
          element: <About />,
        },
        {
          path: PublicRoutes.footballNearYou,
          element: <FootballNearYou />,
        },
        {
          path: PublicRoutes.partners,
          element: <Partners />,
        },
        {
          path: PublicRoutes.supporters,
          element: <Supporters />,
        },
        {
          path: PublicRoutes.articleView,
          element: <ArticleView />,
          children: [
            {
              path: ':articleId',
              element: <ArticleView />,
            },
          ],
        },
        {
          path: PublicRoutes.hashtagView,
          element: <HashtagView />,
          children: [
            {
              path: ':hashtag',
              element: <HashtagView />,
            },
          ],
        },
      ],
    },
    allowedEnvironments.includes(currentEnv) && {
      path: PublicRoutes.offMute,
      children: [
        { index: true, element: <Navigate to='1' replace /> },
        {
          path: ':step',
          element: (
            <Suspense fallback={<PkSpinner />}>
              <OffMute />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: '/index.html',
      element: <Navigate to='/' />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ].filter(Boolean),
);
